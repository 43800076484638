'use client';

import useMultiLanguage from '@hook/useMultiLanguage';
import { useQueryGetPositionListForResumeUpdateV1 } from '@queries/position';
import classnames from 'classnames/bind';
import { ReadonlyURLSearchParams, useRouter, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import Button from 'ui/common/button/Button';
import Icon from 'ui/common/icon/Icon';
import Pagination from 'ui/common/pagination/Pagination';
import Tag from 'ui/common/tag/Tag';
import TextFieldBase from 'ui/common/textfield/TextFieldBase';
import NoResult from 'ui/component/common/NoResult';
import { COLORS } from 'ui/constants/colors';
import useRecruitListDate from 'ui/hook/recruit/useRecruitListDate';
import useDebouncedInput from 'ui/hook/useDebouncedInput';
import usePagination from 'ui/hook/usePagination';
import { ViewModeType } from 'ui/interface/common/ViewMode';
import PaginationVO from 'ui/model/vo/PaginationVO';
import { formatDateWithDay } from 'ui/util/dayUtil';
import styles from './ResumeUpdatePage.module.scss';

const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  isPreview?: boolean;
}

const DEFAULT_PAGE_SIZE = 6;
const ResumeUpdatePage = ({ viewMode, isPreview }: IProps) => {
  const router = useRouter();
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  const { keywordForShow, handleChangeKeyword, filteredKeyword } = useDebouncedInput();
  const { findDateBlockText } = useRecruitListDate();
  const queryParam = useMemo(() => {
    const queryParam = new URLSearchParams({
      keyword: filteredKeyword,
      page: searchParams.get('positionPage') ?? '1',
      size: DEFAULT_PAGE_SIZE.toString(),
    });
    return queryParam.toString();
  }, [filteredKeyword, searchParams.get('positionPage')]);

  const { data: positionListData } = useQueryGetPositionListForResumeUpdateV1(queryParam);
  const { multiLanguageText, languageType } = useMultiLanguage();

  const { list, pagination } = positionListData || {};
  const { changeUrlPage } = usePagination({
    pageName: 'positionPage',
    totalPages: positionListData?.pagination.totalPages || 1,
  });

  const moveToJD = (positionSn: number) => {
    let targetPath = `/career/jobs/${positionSn}`;
    if (languageType !== 'KOR') {
      targetPath += `?languageType=${languageType}`;
    }
    router.push(targetPath);
  };

  const openResumeUpdatePage = (resumeUpdateUrl: string) => {
    window.open(resumeUpdateUrl, '_blank');
  };

  return (
    <div className={cx('container', viewMode)}>
      <div className={cx('main-info-area', viewMode)}>
        <div className={cx('info-text')}>
          <h2 className={cx('title', viewMode)}>{multiLanguageText('지원서 수정', 'Update Resume')}</h2>
          <p className={cx('description', viewMode)}>
            {multiLanguageText(
              '지원서를 수정할 공고를 먼저 선택해주세요.',
              'Please select the job posting to edit your resume.'
            )}
          </p>
        </div>
        <div className={cx('search-bar', viewMode)}>
          <TextFieldBase
            size={'md'}
            placeholder={multiLanguageText('공고 검색하기', 'Search')}
            beforeInput={
              <Icon className={cx('search-icon')} name={'search_line'} color={`${COLORS.gray600}`} size={24} />
            }
            value={keywordForShow}
            onChange={(e) => handleChangeKeyword(e.target.value, () => changeUrlPage(1))}
          />
        </div>
      </div>

      <ul className={cx('position-list')}>
        {!list || !list.length ? (
          <NoResult title={'진행 중인 공고가 없어요.'} subTitle={'혹시 다른 키워드로 검색해보는 건 어떠세요?'} />
        ) : (
          list.map((position) => {
            const { title, positionSn, recruitmentType, dday, startDateTime, endDateTime, resumeUpdateUrl } = position;
            const ddayText = findDateBlockText({ dday, startDateTime, endDateTime });

            const handleMoveModifyResume = () => openResumeUpdatePage(resumeUpdateUrl);

            return (
              <li className={cx('position', viewMode)} onClick={handleMoveModifyResume}>
                <div className={cx('texts')}>
                  <div className={cx('title-area')}>
                    <div className={cx('title')}>{title}</div>
                    {recruitmentType === 'PERMANENT' && (
                      <Tag
                        className={cx('tag', 'always')}
                        variant={'secondary'}
                        color={'green'}
                        round={false}
                        size={'sm'}
                      >
                        {multiLanguageText('상시 채용', 'Ongoing')}
                      </Tag>
                    )}
                  </div>
                  <div className={cx('info-area')}>
                    <Tag className={cx('tag', 'submit')} variant={'primary'} color={'green'} round={false} size={'sm'}>
                      {ddayText}
                    </Tag>
                    <div className={cx('submit-period')}>
                      {`${formatDateWithDay(startDateTime)} ~ ${formatDateWithDay(endDateTime, true, '영입 종료 시')}`}
                    </div>
                  </div>
                </div>
                <div className={cx('buttons', viewMode)}>
                  <Button
                    variant={'outlined'}
                    onClick={(e) => {
                      e.stopPropagation();
                      moveToJD(positionSn);
                    }}
                  >
                    {multiLanguageText('공고 바로가기', 'View Job Posting')}
                  </Button>
                  <Button onClick={handleMoveModifyResume}>
                    {multiLanguageText('지원서 수정하기', 'Edit Resume')}
                  </Button>
                </div>
              </li>
            );
          })
        )}
      </ul>
      <div className={cx('pagination')}>
        <Pagination
          showOnlyPagination
          showDropdown={false}
          pageHandler={({ page }) => changeUrlPage(page)}
          page={
            new PaginationVO({
              pageSize: pagination?.size || DEFAULT_PAGE_SIZE,
              page: pagination?.page || 1,
              totalPages: pagination?.totalPages || 1,
            })
          }
        />
      </div>
    </div>
  );
};

export default ResumeUpdatePage;
